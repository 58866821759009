/* imports and utilities like animations and mixins should be defined here */
@import 'variables';

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}

.xpo-TabDrawer-contentWrapper.mat-drawer-content {
  background: #FFFFFF;
}

.required::after {
  content: '*';
  margin-left: 0.25rem;
}
